'use client';

import { Button } from '@afishauz/ui-kit/button';
import { useEffect } from 'react';

export default function GlobalError({
  error,
  reset,
}: {
  error: Error;
  reset: () => void;
}) {
  useEffect(() => {
    console.error(error);
  }, [error]);

  return (
    <html lang='ru'>
      <body>
        <div className='flex justify-center items-center py-32'>
          <div className='w-[500px] max-w-full flex flex-col items-center'>
            <h1 className='text-5xl text-center font-semibold'>
              Что-то пошло не так!
              <br />
              Работаем над этим
            </h1>
            <div className='mt-6 text-center text-gray-400'>
              Произошли некоторые ошибки, мы уже исправляем их, скоро все
              заработает
            </div>
            <Button className='mx-auto mt-6' onClick={reset}>
              Попробовать еще раз
            </Button>
          </div>
        </div>
      </body>
    </html>
  );
}
